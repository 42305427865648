import { Row, Col } from 'react-bootstrap';
import Layout from './_Layout';
import './Home.scss';

export default function Home() {
  return (
    <Layout>
      <Row>
        <Col xs={8} className="middle-page">
          <p>
            Anna and Duncan got married!
          </p>
        </Col>
      </Row>
    </Layout>
  );
}
