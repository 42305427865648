import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

export default function Navigation({ position }: any) {
  const location = useLocation().pathname;
  
  return (
    <Row>
      <Col>
        <Navbar bg="black" data-bs-theme="dark" expand="lg" sticky={position} className='rounded-pill mt-5'>
          <Container fluid>
            <Nav className="me-auto text-white">
              {location !== "/" &&
                <LinkContainer to={'/'}>
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
              }
              <LinkContainer to={'/Attractions'}>
                <Nav.Link>Attractions</Nav.Link>
              </LinkContainer>
              <LinkContainer to={'/Venue'}>
                <Nav.Link>Venue</Nav.Link>
              </LinkContainer>
              <LinkContainer to={'/Photos'}>
                <Nav.Link>Photos</Nav.Link>
              </LinkContainer>
              <LinkContainer to={'/Registry'}>
                <Nav.Link>Registry</Nav.Link>
              </LinkContainer>
            </Nav>
          </Container>
        </Navbar>
      </Col>
    </Row>
  );
}