import { Row, Col } from 'react-bootstrap';
import Layout from './_Layout';

export default function Registry() {
  return (
    <Layout>
      <Row>
        <Col xs={8} className="middle-page">
            <p>
              You celebrating with us is all we could ask for, but if you really want to get us something, check out
              <a href="https://withjoy.com/anna-and-duncan-eclvwzix2q001801z2huwb4xk2s/registry"> our registry</a>
            </p>
        </Col>
      </Row>
    </Layout>
  );
}
