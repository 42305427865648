import { Row, Col } from 'react-bootstrap';
import Layout from '../_Layout';

export default function Attractions() {
  return (
    <Layout>
      <Row className="my-5">
        <Col xs={8} className="middle-page text-start">
          <p>
            Here are some suggestions for trips and activities that you may wish to consider during your stay near Lake of Menteith.
          </p>
          <p>
          <a href="https://www.getyourguide.com/-l91026/">Trossachs National Park</a>
          </p>
          <p>
          <a href="https://lochlomond-thetrossachs.co.uk/guided-tours/">Guided Tours - Loch Lomond</a>
          </p>
          <p>
          <a href="https://trossachs.co.uk/whisky/">Distillery Tours</a>
          </p>
          <p>
          <a href="https://www.tripadvisor.co.uk/Attractions-g186568-Activities-c42-Trossachs_Loch_Lomond_and_The_Trossachs_National_Park_Scotland.html">Trossachs Tours</a>
          </p>
          <p>You can also book tours at our local distilleries:</p>
          <p>
          <a href="https://deanstonmalt.com/">Deanston Distillery</a>
          </p>
          <p>
          <a href="https://www.glengoyne.com/">Glengoyne</a>
          </p>
          <p>We are right next to Lake of Menteith Fisheries where you can hire a boat and fish for trout:</p>
          <a href="https://www.menteith-fisheries.co.uk/">Lake of Menteith Fisheries</a>
          <p>Boat trips on Loch Katrine run most days:</p>
          <a href="https://www.lochkatrine.com/">Loch Katrine Cruises</a>
          <p>If you enjoy visiting castles there are two nearby the hotel - 
            <a href="https://www.historicenvironment.scot/visit-a-place/places/doune-castle/"> Doune Castle </a> 
            and 
            <a href="https://www.stirlingcastle.scot/"> Stirling Castle</a>
          </p>
          <p>If you are looking for country walks you are spoilt for choice in nearby Aberfoyle. Just 4 miles from the hotel you will find the 
            <a href="https://forestryandland.gov.scot/visit/forest-parks/queen-elizabeth-forest-park"> Queen Elizabeth Forest Park and the Lodge visitor centre</a> 
            where walks are plentiful.
          </p>
          <p>There are lots of nice villages in the area and the larger cities are also worth a visit.</p>
          <p>Essentially, lots to do and lots to see.</p>
        </Col>
      </Row>
    </Layout>
  );
}