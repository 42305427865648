import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Error from "./Error";
import Registry from './routes/Registry';
import Photos from './routes/Photos';
import Venue from './routes/Guests/Venue';
import Attractions from './routes/Guests/Attractions';
import Home from './routes/Home';

const router = createBrowserRouter([
  {
    path: "/Attractions",
    element: <Attractions />
  },
  {
    path: "/Photos",
    element: <Photos />
  },
  {
    path: "/Registry",
    element: <Registry />
  },
  {
    path: "Venue",
    element: <Venue />
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
