import { Container, Row, Col } from 'react-bootstrap';
import Navigation from '../Navigation';

const Layout = ({ children }: any)  => {
  return (
    <Container fluid className="background">
      <Row className='vw-100'>
        <Col className='d-flex flex-column'>
          <Row>
            <Col>
              <Navigation position="top" />
            </Col>
          </Row>
          <Row className='col'>
            <Col className='my-auto'>
              <main>{children}</main>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Layout;