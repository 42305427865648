import { Row, Col, Image } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import Layout from './_Layout';
import './Photos.scss';

export default function Photos() {
  const [photos, setPhotos] = useState<string[]>([]);
  const [selected, setSelected] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (url: string) => {
    setSelected(url);
    setShow(true);
  }

  useEffect(() => {
    fetch(`https://duncanna.blob.core.windows.net/photos?restype=container&comp=list`).then(resp => resp.text()).then(response => {
      const xmlDoc = new DOMParser().parseFromString(response, "text/xml");  
      const items = xmlDoc.getElementsByTagName("Url");
      let urls = []
      for(const item of items) {
        urls.push(item.innerHTML);
      }
      setPhotos(urls);
      })
  }, [])


  
  return (
    <Layout>
      <Row className="p-5">
        {photos.map((url: string) => 
          <Col key={url} xs={10} sm={3} className="p-3 d-flex flex-column justify-content-center">
              <Image className="bg-black p-3" fluid src={url} onClick={() => handleShow(url)} />
          </Col>
        )}
      </Row>
      <Modal show={show} onHide={handleClose} fullscreen={true} >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Row>
            <Col className="d-flex flex-column">
              <div>
                <Image className="modal-img" src={selected} />
              </div>
              <a href={selected} target="_blank" rel="noreferrer noopener">View in browser</a>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}