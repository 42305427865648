import { Col, Container, Row } from "react-bootstrap";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function Error() {
  let error = useRouteError();
  
  return (
    <Container fluid className="background">
      <Row className="vh-100">
        <Col className="my-auto text-center">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{isRouteErrorResponse(error) ? error.statusText : "Unknown Error"}</i>
          </p>
        </Col>
      </Row>
    </Container>
  );
 }