import { Row, Col, Image } from 'react-bootstrap';
import Layout from '../_Layout';

export default function Venue() {
  return (
    <Layout>
      <Row>
        <Col xs={8} className="middle-page my-5">
          <Image src="https://storage.karmagroup.com/assets/karmagroup.com/2022/01/517dcaa3-karma_lake_of_menteith_slider_06.jpg" fluid />
          <p>
            We're very excited to be celebrating with you at the <a href="https://karmagroup.com/find-destination/karma-resorts/karma-lake-of-menteith/">Lake of Mentieth Hotel</a> in Port of Mentieth, Scotland.
          </p>
        </Col>
      </Row>
    </Layout>
  );
}